<template>
  <div>
    <div class="body-box">
      <div style="width: 200px;float:left;">
        <el-menu
            :default-active="selectName"
            class="el-menu-vertical-demo"
            style="text-align: center"
            @select="handleOpen">
          <el-menu-item index="1" v-if="this.$getPermission('/pc/equi/getList')">
            <span slot="title">设备信息</span>
          </el-menu-item>
          <el-menu-item index="2" v-if="this.$getPermission('/pc/faci/getList')">
            <span slot="title">控制器信息</span>
          </el-menu-item>
          <el-menu-item index="3" v-if="this.$getPermission('/pc/videos/getList')">
            <span slot="title">监控信息</span>
          </el-menu-item>

        </el-menu>
      </div>
      <div class="agricul_right ib">
        <div v-if="selectName == 1">
          <device-message  :greenHouseList="greenHouseList" ></device-message>
        </div>
        <div v-if="selectName == 2">
          <controller-message :greenHouseList="greenHouseList"></controller-message>
        </div>
        <div v-if="selectName == 3">
          <monitoring-message :greenHouseList="greenHouseList"></monitoring-message>
        </div>

        <div class="clear"></div>
      </div>
    </div>
  </div>
</template>

<script>
import deviceMessage from '@/views/user/deviceMessage'
import controllerMessage from '@/views/user/controllerMessage'
import monitoringMessage from '@/views/user/monitoringMessage'

export default {
  components: {
    deviceMessage,
    controllerMessage,
    monitoringMessage
  },
  data() {
    return {
      selectName: localStorage.getItem('selectIndex'),
      greenHouseList: [], //大棚列表
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$api.post('gHouse/select',  //大棚列表
          {}, res => {
            console.log(res, '123')
            this.greenHouseList = res.data
          });


    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
      this.selectName = key
    },
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
  }
}
</script>

<style scoped>
.body-box {
  margin-top: 20px;
}

.ib {
  /*display: inline-block;*/
}

.agricul_right {
  margin-left: 10px;
  width: 990px;

  background: #FFFFFF;
  float: left;
}

.clear {
  clear: both
}

.head-name-title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  height: 57px;
  line-height: 57px;
  border-bottom: 2px solid #E8E8E8;
}
</style>
