<template>
  <div style="height: 640px;width: 400px;text-align: center;margin-left: 80px">
    <div class="dialog-title">{{ buttonText }}设备</div>
    <el-form :label-position="labelPosition" label-width="100px">
      <el-form-item label="设备名称">
        <el-input v-model="dataForm.name"></el-input>
      </el-form-item>

      <el-form-item label="设备序列号">
        <el-input v-model="dataForm.code "></el-input>
      </el-form-item>
      <el-form-item label="所属大棚">
        <el-select v-model="dataForm.gId " placeholder="请选择" style="width: 300px;" @change="getGreenHouse($event)">
          <el-option
              v-for="item in greenHouseList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属控制器">
        <el-select v-model="dataForm.fId" placeholder="请选择" style="width: 300px;">
          <el-option
              v-for="item in faciList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="类型">
        <el-select v-model="dataForm.type" placeholder="请选择" style="width: 300px;">
          <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="设备类型" v-if="this.id">
        <el-input v-model="dataForm.typeId "></el-input>

      </el-form-item>
      <el-form-item label="设备类型" v-else>
        <el-select v-model="dataForm.typeId" placeholder="请选择" style="width: 300px;">
          <el-option
              v-for="item in typeIdList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="设备状态">
        <el-select v-model="dataForm.status " placeholder="请选择" style="width: 300px;">
          <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="关联视频">
        <el-select v-model="dataForm.vId" placeholder="请选择" style="width: 300px;">
          <el-option
              v-for="item in videosList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-col>
      <el-button class="dialog-button" @click="updateGreenHouse">{{ buttonText }}</el-button>
    </el-col>
  </div>
</template>

<script>
export default {
  inject: [
    'reload'
  ],
  data() {

    return {
      id: '',
      buttonText: '添加',
      dataForm: {
        "code": "",
        "fId": '',
        "frontSide": 2,
        "gId": '',
        "name": "",
        "status": '',
        "type": '',
        "typeId": '',
        "vId": ''
      },
      labelPosition: 'right',
      name: '',
      area: '',
      product: '',
      options: [],
      faciList:[],
      value: '',
      statusList: [
        {
          name: '在线',
          id: 1
        }, {
          name: '离线',
          id: 0
        }
      ],
      typeList: [
        {
          name: '只有开关',
          id: 1
        }, {
          name: '正反转和开关',
          id: 2
        }
      ],
    }
  },
  props: {
    videosList: {
      type: Array,
    },
    greenHouseList: {
      type: Array,
    },
    changeList: {
      type: Object,
    },
    typeIdList: {
      type: Array,
    }
  },
  mounted() {
    if (this.changeList.name) {
      this.id = this.changeList.id
      this.dataForm.code = this.changeList.code
      this.dataForm.fId = this.changeList.fId
      this.dataForm.frontSide = this.changeList.frontSide
      this.dataForm.gId = this.changeList.gId
      this.dataForm.name = this.changeList.name
      this.dataForm.status = this.changeList.status
      this.dataForm.type = this.changeList.type
      this.dataForm.typeId = this.changeList.typeId
      this.dataForm.vId = this.changeList.vId
      this.buttonText = '修改'
    }
  },
  methods: {
    clearData() {
      this.dataForm = {
        "code": "",
        "fId": '',
        "frontSide": 2,
        "gId": '',
        "name": "",
        "status": '',
        "type": '',
        "typeId": '',
        "vId": ''
      }
    },
    getGreenHouse(val){
      let _selef =this
      console.log(val,'选择的值')
      this.$api.post('faci/select',  //控制箱列表
          {
            gid:val
          }, res => {
            console.log(res, '123')
            _selef.faciList = res.data
          });
    },
    updateGreenHouse() {
      if (this.id !== '') { //修改
        this.dataForm['id'] = this.id
        this.$api.post('equi/update', this.dataForm, res => {
          console.log(res, 'wwww')
          if (res.code === 0) {
            console.log('修改')
            this.reload()
          }
        })
      } else {
        this.$api.post('equi/save', this.dataForm, res => {
          console.log(res, 'wwww')
          if (res.code === 0) {
            console.log('添加成功')
            this.reload()
          }
        })
      }

    }
  },
  watch: {
    changeList: {
      handler: function (val) {
        if (val.name) {
          this.id = val.id
          this.dataForm.code = val.code
          this.dataForm.fId = val.fId
          this.dataForm.frontSide = val.frontSide
          this.dataForm.gId = val.gId
          this.dataForm.name = val.name
          this.dataForm.status = val.status
          this.dataForm.type = val.type
          this.dataForm.typeId = val.typeId
          this.dataForm.vId = val.vId
          this.buttonText = '修改'
        } else {
          this.id = ''
          this.dataForm.code = ''
          this.dataForm.fId = ''
          this.dataForm.frontSide = ''
          this.dataForm.gId = ''
          this.dataForm.name = ''
          this.dataForm.status = ''
          this.dataForm.type = ''
          this.dataForm.typeId = ''
          this.dataForm.vId = ''
          this.buttonText = '添加'
        }
      }
    }
  }
}
</script>

<style scoped>
.dialog-title {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-bottom: 30px;
}

.dialog-button {
  height: 60px;
  background: #009400;
  border-radius: 30px;
  width: 400px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FEFEFE;
  margin-top: 30px;
}
</style>
